import axios from '../../plugins/axios'

// 获取终鉴中/终鉴完成列表
const getFinalIdentifyList = (options) => axios.post('/v1/collection/search/final/expert', options)

const applyFinalIdentify = (options) => axios.post('/v1/collection/final/appraisal', options)

const getDeptList = (options) => axios.post('/v1/department/search', options)

// 确真机构列表
const getAuthenticityDeptList = (options) => axios.post('/v1/department/by/type/real', options)

// 终鉴综合页
const getSummaryInfo = (options) => axios.get('/v1/collection/identify/complex/get/' + options.archiveId)

// 获取确真详情
const getAuthenticityDetail = (options) => axios.get('/v1/collection/identify/real/get/' + options.archiveId)

// 保存确真项目检测结果
const saveOptionInfo = (options) => axios.post('/v1/detect/save/info', options)

// 提交确真结果
const submitAuthenticityDetail = (options) => axios.post('/v1/collection/identify/real/commit', options)

// 获取确权详情
const getNotarizationDetail = (options) => axios.get('/v1/collection/identify/authority/get/' + options.archiveId)

// 提交确权结果
const submitNotarizationDetail = (options) => axios.post('/v1/collection/identify/authority/commit', options)

// 获取确值详情
const getValueDetail = (options) => axios.get('/v1/collection/identify/value/get/' + options.archiveId)

// 提交确值结果
const submitValueDetail = (options) => axios.post('/v1/collection/identify/value/commit', options)

// 获取法律意见详情
const getLawDetail = (options) => axios.get('/v1/collection/identify/legal/get/' + options.archiveId)

// 提交法律意见
const submitLawDetail = (options) => axios.post('/v1/collection/identify/legal/commit', options)

// 终鉴最后提交完成
const submitFinalInfo = (options) => axios.post('/v1/collection/identify/commit/final/appraisal', options)

// 获取终鉴证书
const getFinalCertificate = (options) => axios.get('/v1/certificate/archive/final/' + options.archiveId)

export default {
  getFinalIdentifyList,
  applyFinalIdentify,
  getDeptList,
  getAuthenticityDeptList,
  getSummaryInfo,
  getAuthenticityDetail,
  saveOptionInfo,
  submitAuthenticityDetail,
  getNotarizationDetail,
  submitNotarizationDetail,
  getValueDetail,
  submitValueDetail,
  getLawDetail,
  submitLawDetail,
  submitFinalInfo,
  getFinalCertificate
}
