<template>
  <div class="ym-no-record-com">
    <slot>暂无数据</slot>
  </div>
</template>

<script>
export default {
  name: 'no-record',
  data() {
    return {}
  },

  created() {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.ym-no-record-com{
  /*width: 400px;*/
  text-align: center;
  padding: 30px;
  color: $--color-text-secondary;
  font-size: 16px;
}
</style>
