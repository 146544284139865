'use strict'

import Vue from 'vue'
import axios from 'axios'
import baseUrl from '../config'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
import { getToken, getUserInfo } from '@/utils/localStorage.js'
import router from '@/router'

// import Qs from 'qs'

let config = {
  baseURL: baseUrl.baseUrl,
  timeout: 10000,
  // withCredentials: false,
  headers: {
    'Cache-Control': 'no-cache,no-store,max-age=-1,private',
    'Content-Type': 'application/json;charset=utf-8'
  }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = getToken()
    if (!config.headers.hasOwnProperty('dreamkey-token') && token) {
      config.headers['dreamkey-token'] = token
    }
    // if (config.url.indexOf('/vue-mock/') >= 0) {
    //   config.baseURL = baseUrl.baseMockUrl
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    const res = response.data
    // console.log('http response', res)
    if (response.status === 200) {
      if (getToken() && getUserInfo() && res.code === 10202) {
        const isAsking = store.state.user.isAskLogin
        if (!isAsking) {
          store.commit('SET_IS_ASK_LOGIN', true)
          store.dispatch('LogoutLocal')
          MessageBox.confirm('当前登录已超时或未登录，请重新登录', '重新登录', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(() => {
            // store.dispatch('logout').then(() => {
            //   router.push({ name: 'login' })
            // })
            router.push({ name: 'login' })
            // router.push({ name: 'login' })
            // window.location.href = window.location.host
          })
        }
        // return Promise.reject(new Error(res.message || 'Error'))
      } else {
        store.commit('SET_IS_ASK_LOGIN', false)
        store.commit('REFRESH_COUNTDOWN')
        return Promise.resolve(response)
      }
    }
  },
  error => {
    console.log('error response')
    console.log(error.response)
    let axiosErrorMessageMap = {
      '404': '404接口路径异常，',
      '400': '未授权,请求要求身份验证，',
      '408': '服务器等候请求时发生超时，',
      '409': '服务器在完成请求时发生冲突，',
      '411': '服务器不接受不含有效内容长度标头字段的请求，',
      '412': '服务器未满足请求者在请求中设置的其中一个前提条件，',
      '413': '请求实体过大，超出服务器的处理能力，',
      '414': '请求的URI过长，服务器无法处理，',
      '415': '请求的格式不受请求页面的支持，',
      '416': '无法提供请求的范围，',
      '500': '服务器遇到错误，无法完成请求，',
      '501': '服务器不具备完成请求的功能，',
      '502': '服务器作为网关或代理，从上游服务器收到无效响应，',
      '503': '服务器目前无法使用（由于超载或停机维护），',
      '504': '服务器没有及时从上游服务器收到请求，',
      '505': '服务器不支持请求中所用的 HTTP 协议版本，'
    }
    let errorMessage
    if (error && error.response && error.response.status) {
      errorMessage = axiosErrorMessageMap[error.response.status]
    } else {
      switch (error.code) {
        case 'ECONNABORTED': errorMessage = '请求超时，'; break
        case 'ECONNRESET': errorMessage = '请求超时，'; break
        case 'ETIMEDOUT': errorMessage = '连接超时，服务端请求中断，'; break
        case 'EPIPE': errorMessage = '请求超时，管道破裂，'; break
        default: errorMessage = '网络超时，'
      }
      // errorMessage = '未知错误，'
    }
    errorMessage = '错误：' + errorMessage + '请稍后再试'
    Message({
      type: 'error',
      message: errorMessage
    })
    return Promise.reject(errorMessage)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default _axios
