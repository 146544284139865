import axios from '../../plugins/axios'
// 登录
const login = (options) => axios.post('/v1/sessions', options)
// 退出登录
const logout = () => axios.delete('/v1/sessions')
// 初始化
const init = (options) => axios.post('/v1/system', options)
// 查询是否初始化
const checkInit = () => axios.get('/v1/system')
// 存在sessionId时，获取菜单
const getRouters = () => axios.get('/v1/privileges/user/menus')
// 获取用户分页列表
const getUserListByPage = (options) => axios.post('/v1/search/managers', options)
// 获取用户不分页列表
const getUserList = () => axios.get('/v1/managers')
// 新增用户
const addUser = (options) => axios.post('/v1/managers', options)
// 修改用户
const updateUser = (options) => axios.put('/v1/managers/' + options.userId, options)
// 获取个人中心用户信息
const getPersonalInfo = () => axios.get('/v1/managers/person/center/info')
// 更新个人中心用户信息
const updatePersonalInfo = (options) => axios.put('/v1/managers/person/center', options)
// 删除用户
const deleteUser = (options) => axios.delete('/v1/managers/' + options)
// 重置密码
const resetUserPassword = (options) => axios.post('/v1/managers/' + options + '/password')

const changePassword = (options, id) => axios.put(`/v1/managers/${id}/password`, options)

const autoLogin = () => axios.get('/v1/sessions')

const keepAlive = () => axios.put('/v1/sessions')

const getDataPermission = () => axios.get('/v1/roles/functionTags')

export default {
  login,
  logout,
  init,
  checkInit,
  getRouters,
  getUserListByPage,
  getUserList,
  addUser,
  updateUser,
  getPersonalInfo,
  updatePersonalInfo,
  deleteUser,
  resetUserPassword,
  changePassword,
  autoLogin,
  keepAlive,
  getDataPermission
}
