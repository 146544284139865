<template>
  <el-breadcrumb class="ym-breadcrumb" v-if="show" v-bind="$attrs" :style="`margin-bottom:${bottom}px`">
    <!-- <transition-group name="fade-transverse" mode="out-in"> -->
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
          v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
          class="ym-no_redirect"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    <!-- </transition-group> -->
  </el-breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex'
const pathToRegexp = require('path-to-regexp')
export default {
  props: {
    useDefault: {
      type: Boolean,
      default: true
    },
    defaultRoute: {
      type: Object,
      default: () => {}
    },
    bottom: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      levelList: null,
      routeList: null,
      show: true,
      firstRoute: null
    }
  },
  computed: {
    ...mapGetters(['sidebarRouters'])
  },
  watch: {
    $route(route) {
      if (route.path.startsWith('/redirect/')) {
        return
      }
      this.getBreadcrumb()
      this.isShow()
    }
  },
  created() {
    this.getBreadcrumb()
    this.isShow()
    // console.log(1111, this.$router)
  },
  methods: {
    getBreadcrumb() {
      if (!this.routeList) {
        let routes = this.sidebarRouters.length >= this.$router.options.routes.length ? this.sidebarRouters : this.$router.options.routes
        this.routeList = this.steamroller(routes)
        // console.log(1, this.routeList)
      }
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      )
      const first = matched[0]
      if (!this.isDashboard(first) && this.useDefault) {
        if (!this.firstRoute) {
          if (this.defaultRoute && this.defaultRoute.name) {
            this.firstRoute = this.defaultRoute
          } else {
            if (this.routeList[0].redirect) {
              this.firstRoute = this.routeList.find(item => item.path === this.routeList[0].redirect)
            } else {
              this.firstRoute = this.routeList[0]
            }
          }
        }
        matched = [this.firstRoute].concat(matched)
      }
      let arr = []
      matched.forEach(item => {
        if (item.meta && item.meta.parent) {
          arr = arr.concat([this.findOne(this.routeList, item.meta.parent), item])
        } else {
          arr.push(item)
        }
      })
      arr = this.unique(arr, 'path')
      this.levelList = arr.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      )
    },
    isShow() {
      if (this.$route.meta.breadcrumbShow === false) {
        this.show = false
      } else {
        this.show = true
      }
    },
    // 数组中查询某个值返回
    findOne(arr, params) {
      return arr.find(item => item.name === params)
    },
    // 数组扁平化
    steamroller(arr) {
      return arr.reduce((prev, next) => {
        return prev.concat(Array.isArray(next.children) ? [next, ...this.steamroller(next.children)] : next)
      }, [])
    },
    // 去重
    unique(arr, key) {
      const res = new Map()
      return arr.filter((a) => !res.has(a[key]) && res.set(a[key], 1))
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'home'.toLocaleLowerCase()
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      let { redirect, path, meta } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      if (meta.link === false) return
      this.$router.push(this.pathCompile(path))
      // this.$router.push({ name: name })
    }
  }
}
</script>

<style lang="scss">
.ym-breadcrumb {
  .el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
    color: $--color-text-secondary;
    font-weight: normal;
    &:hover {
      color: $--color-primary;
    }
  }
  .ym-no_redirect {
    color:  $--color-text-primary;
    font-weight: bold;
  }
}
</style>
