import TransitionRouter from './transition-router.vue'
import NoRecord from './NoRecord/index.vue'
import Breadcrumb from './Breadcrumb'
const myComponents = {
  install: function (Vue) {
    Vue.component('TransitionRouter', TransitionRouter)
    Vue.component('NoRecord', NoRecord)
    Vue.component('Breadcrumb', Breadcrumb)
  }
}

// 导出组件
export default myComponents
