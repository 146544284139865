import router, { resetRouter } from '@/router'
import store from '@/store'
import { Message } from 'element-ui'
import { getToken, removeToken } from './localStorage.js'

// import store from '@/store'

// 判断是否需要登录权限 以及是否登录
// router.beforeEach((to, from, next) => {
//   const userInfo = JSON.parse(store.state.user.userInfo)
//   const accessList = ['/login', '/register']
//   if (accessList.includes(to.path)) {
//     next()
//   } else if (!userInfo) {
//     next({
//       path: '/login'
//     })
//   } else if (!(!to.meta.roleTypeShow ? [] : to.meta.roleTypeShow.indexOf(userInfo.companyTypeId) >= 0)) {
//     next({
//       path: '/exception/404'
//     })
//   } else {
//     next()
//   }
// })

const accessList = ['/login', '/404', '/403', '/500']

router.beforeEach((to, from, next) => {
  console.log('from:' + from.path + '，to:' + to.path)
  if (accessList.includes(to.path)) {
    // 没有token
    console.log(to.path)
    next()
  } else if (getToken()) {
    // if (!getUserInfo()) {
    //   store.dispatch('autoLogin').then(() => {
    //     // console.log('auto get userInfo')
    //     getRouters(to, next)
    //   }).catch(err => {
    //     if (err === 10202) {
    //       // console.log('check 10202')
    //       removeToken()
    //     } else {
    //       // console.log('permission err')
    //       Message.error(err)
    //     }
    //     next({ name: 'login' })
    //   })
    // } else {
    //   getRouters(to, next)
    // }
    store.dispatch('autoLogin').then(() => {
      // console.log('auto get userInfo')
      getRouters(to, next)
    }).catch(err => {
      if (err === 10202) {
        // console.log('check 10202')
        removeToken()
      } else {
        // console.log('permission err')
        console.log(err)
      }
      next({ name: 'login' })
    })
  } else {
    next({ name: 'login' }) // 否则全部重定向到登录页
  }
})

let getRouters = (to, next) => {
  const routers = store.state.user.addRoutes.slice() // 动态路由相关
  if (window.PERMISSIVE_ROUTES && routers.length === 0) {
    // 动态路由相关
    // 获取动态路由
    store.dispatch('GenerateRoutes').then(accessRoutes => {
      // 根据roles权限生成可访问的路由表
      // console.log('accessRoutes', accessRoutes)
      // console.log('重复检测', router)
      resetRouter()
      router.addRoutes(accessRoutes) // 动态添加可访问路由表
      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
    }).catch(err => {
      Message.error(err)
      next()
    })
  } else {
    next()
  }
}
