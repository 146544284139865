import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout/index.vue'
// import Content from '@/components/Layout/content.vue'

Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export const constantRoutes = [
  {
    path: '/home',
    component: Layout,
    redirect: '/',
    hidden: true
  },
  // ========== 不使用动态路由时使用 Start ===============
  // {
  //   path: '/',
  //   hidden: true,
  //   redirect: '/record'
  // },
  // ============ 不使用动态路由时使用 End =================
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('../views/application/login'),
    meta: { title: '登录' }
  },
  {
    path: '/setting',
    component: Layout,
    hidden: true,
    redirect: '/setting/personalCenter',
    meta: { title: '设置', link: false },
    children: [
      {
        path: '/setting/personalCenter',
        name: 'personal-center',
        hidden: true,
        component: () => import('../views/application/personalCenter'),
        meta: { title: '个人中心', menuShow: false }
      },
      {
        path: '/setting/password',
        name: 'password',
        hidden: true,
        component: () => import('../views/application/password'),
        meta: { title: '修改密码', menuShow: false }
      }
    ]
  },
  {
    path: '/certificate/template/:archiveId',
    name: 'certificate-template',
    hidden: true,
    component: () => import('../views/record/component/certificate/index.vue'),
    meta: { title: '证书预览下载' }
  }
  // --------------------------------------------------------------
  // {
  //   path: '/record',
  //   name: 'record',
  //   redirect: '/record/list',
  //   component: Layout,
  //   meta: { title: '档案管理', breadcrumb: false, icon: 'ym-icon-yinhangka' },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'recordList',
  //       hidden: true,
  //       component: () => import('../views/record/list.vue'),
  //       meta: { title: '档案列表', breadcrumbShow: false, activeMenu: '/record' }
  //     },
  //     {
  //       path: 'details',
  //       hidden: true,
  //       name: 'recordDetails',
  //       component: () => import('../views/record/details/details.vue'),
  //       meta: { title: '档案详情', breadcrumbShow: false, parent: 'recordList', activeMenu: '/record' }
  //     },
  //     {
  //       path: '/record/application/:archiveId',
  //       hidden: true,
  //       name: 'application',
  //       component: () => import('../views/record/application/index.vue'),
  //       meta: { title: '终鉴申请', breadcrumbShow: false, parent: 'recordList', activeMenu: '/record' }
  //     }
  //   ]
  // },
  // {
  //   path: '/audit',
  //   name: 'audit',
  //   component: Layout,
  //   redirect: '/audit/list',
  //   meta: { title: '审核管理', breadcrumb: false, icon: 'el-icon-s-check' },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'auditList',
  //       hidden: true,
  //       component: () => import('../views/audit/list.vue'),
  //       meta: { title: '审核列表', breadcrumbShow: false, activeMenu: '/audit' }
  //     },
  //     {
  //       path: 'details',
  //       hidden: true,
  //       name: 'auditDetails',
  //       component: () => import('../views/record/details/details.vue'),
  //       meta: { title: '审核档案详情', breadcrumbShow: false, activeMenu: '/audit' }
  //     }
  //   ]
  // },
  // {
  //   path: '/identify',
  //   name: 'identify',
  //   component: Layout,
  //   meta: { title: '鉴定管理', breadcrumbShow: false, icon: 'el-icon-view' },
  //   children: [
  //     {
  //       path: '/identify/first-identify',
  //       name: 'firstIdentify',
  //       redirect: '/identify/first-identify/list',
  //       component: Content,
  //       meta: { title: '初鉴管理', breadcrumbShow: false },
  //       children: [
  //         {
  //           path: '/identify/first-identify/list',
  //           name: 'identifyList',
  //           hidden: true,
  //           component: () => import('../views/identify/list.vue'),
  //           meta: { title: '初鉴管理列表', breadcrumbShow: false, activeMenu: '/identify/first-identify' }
  //         },
  //         {
  //           path: '/identify/first-identify/details',
  //           hidden: true,
  //           name: 'identifyDetails',
  //           component: () => import('../views/record/details/details.vue'),
  //           meta: { title: '鉴定档案详情', breadcrumbShow: false, activeMenu: '/identify/first-identify' }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/identify/final-identify',
  //       name: 'finalIdentify',
  //       redirect: '/identify/final-identify/list',
  //       component: Content,
  //       meta: { title: '终鉴管理', breadcrumbShow: false },
  //       children: [
  //         {
  //           path: '/identify/final-identify/list',
  //           name: 'finalIdentifyList',
  //           hidden: true,
  //           component: () => import('../views/finalIdentify/list.vue'),
  //           meta: { title: '终鉴管理列表', breadcrumbShow: false, activeMenu: '/identify/final-identify' }
  //         },
  //         {
  //           path: '/identify/final-identify/details',
  //           hidden: true,
  //           name: 'finalIdentifyDetails',
  //           component: () => import('../views/record/details/details.vue'),
  //           meta: { title: '鉴定档案详情', breadcrumbShow: false, activeMenu: '/identify/final-identify' }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/order',
  //   name: 'order',
  //   component: Layout,
  //   meta: { title: '订单管理', breadcrumbShow: false, icon: 'el-icon-tickets' },
  //   children: [
  //     {
  //       path: '/order/first-identify',
  //       name: 'firstIdentifyOrder',
  //       redirect: '/order/first-identify/list',
  //       component: Content,
  //       meta: {
  //         title: '初鉴订单',
  //         breadcrumbShow: false
  //       },
  //       children: [
  //         {
  //           path: '/order/first-identify/list',
  //           name: 'firstIdentifyOrderList',
  //           hidden: true,
  //           component: () => import('../views/order/firstIdentify/index.vue'),
  //           meta: { title: '初鉴订单列表', breadcrumbShow: false, activeMenu: '/order/first-identify' }
  //         },
  //         {
  //           path: '/order/first-identify/details',
  //           name: 'firstIdentifyOrderDetails',
  //           hidden: true,
  //           component: () => import('../views/record/details/details.vue'),
  //           meta: { title: '初鉴订单详情', breadcrumbShow: false, activeMenu: '/order/first-identify' }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/order/final-identify',
  //       name: 'finalIdentifyOrder',
  //       redirect: '/order/final-identify/list',
  //       component: Content,
  //       meta: {
  //         title: '终鉴订单',
  //         breadcrumbShow: false
  //       },
  //       children: [
  //         {
  //           path: '/order/final-identify/list',
  //           name: 'finalIdentifyOrderList',
  //           hidden: true,
  //           component: () => import('../views/order/finalIdentify/index.vue'),
  //           meta: { title: '终鉴订单列表', breadcrumbShow: false, activeMenu: '/order/final-identify' }
  //         },
  //         {
  //           path: '/order/final-identify/details',
  //           name: 'finalIdentifyOrderDetails',
  //           hidden: true,
  //           component: () => import('../views/record/details/details.vue'),
  //           meta: { title: '终鉴订单详情', breadcrumbShow: false, activeMenu: '/order/final-identify' }
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/authority',
  //   name: 'authority',
  //   component: Layout,
  //   meta: { title: '权限管理', breadcrumbShow: false, icon: 'el-icon-lock' },
  //   children: [
  //     {
  //       path: '/authority/department',
  //       name: 'department',
  //       redirect: '/authority/department/list',
  //       component: Content,
  //       meta: {
  //         title: '组织架构',
  //         breadcrumbShow: false
  //       },
  //       children: [
  //         {
  //           path: '/authority/department/list',
  //           name: 'departmentList',
  //           component: () => import('../views/department/index.vue'),
  //           meta: { title: '组织架构', breadcrumbShow: false }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/authority/user',
  //       name: 'user',
  //       redirect: '/authority/user/list',
  //       component: Content,
  //       meta: { title: '用户' },
  //       children: [
  //         {
  //           path: '/authority/user/list',
  //           name: 'user-list',
  //           component: () => import('../views/application/user/list.vue'),
  //           meta: { title: '用户列表', breadcrumbShow: false }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/authority/userGroup',
  //       name: 'userGroup',
  //       redirect: '/authority/userGroup/list',
  //       component: Content,
  //       meta: { title: '用户组' },
  //       children: [
  //         {
  //           path: '/authority/userGroup/list',
  //           name: 'userGroup-list',
  //           component: () => import('../views/application/userGroup/list.vue'),
  //           meta: { title: '用户组列表', breadcrumbShow: false }
  //         },
  //         {
  //           path: '/authority/userGroup/edit',
  //           name: 'userGroup-edit',
  //           hidden: true,
  //           component: () => import('../views/application/userGroup/edit.vue'),
  //           meta: { title: '新增用户组', breadcrumbShow: false, activeMenu: '/authority/userGroup/list' }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/authority/userRole',
  //       name: 'userRole',
  //       redirect: '/authority/userRole/list',
  //       component: Content,
  //       meta: { title: '用户角色' },
  //       children: [
  //         {
  //           path: '/authority/userRole/list',
  //           name: 'userRole-list',
  //           component: () => import('../views/application/userRole/list.vue'),
  //           meta: { title: '角色列表', breadcrumbShow: false }
  //         },
  //         {
  //           path: '/authority/userRole/edit',
  //           name: 'userRole-edit',
  //           hidden: true,
  //           component: () => import('../views/application/userRole/edit.vue'),
  //           meta: { title: '新增角色', breadcrumbShow: false, activeMenu: '/authority/userRole/list' }
  //         }
  //       ]
  //     }
  //   ]
  // }
  // ============== 生成动态路由使用页 Start =====================
  // {
  //   path: '/test',
  //   component: () => import('../views/test/index.vue')
  // }
  // ============== 生成动态路由使用页 End =====================
]

const createRouter = () => new Router({
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
