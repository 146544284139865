<template>
  <el-menu class="ym-menu"
           :default-active="activeMenu"
           :collapse="isCollapse"
           :unique-opened="uniqueOpened"
           :collapse-transition="collapseTransition"
           :mode="mode"
           :menu-trigger="menuTrigger"
           @select="onSelect"
           @open="onOpen"
           @close="onClose">
    <sidebar-item v-for="route in routes"
                  :key="route.path"
                  :item="route"
                  :base-path="route.path" />

  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  name: 'ymMenu',
  components: {
    SidebarItem
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    // 模式:horizontal / vertical
    mode: {
      type: String,
      default: 'vertical'
    },
    // 子菜单打开的触发方式(只在 mode 为 horizontal 时有效)
    menuTrigger: {
      type: String,
      default: 'hover'
    },
    // 是否开启折叠动画
    collapseTransition: {
      type: Boolean,
      default: false
    },
    // 是否只保持一个子菜单的展开
    uniqueOpened: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  created() {
    // console.log(this.logoOption)
  },
  methods: {
    onSelect(index, indexPath) {
      // index: 选中菜单项的 index, indexPath: 选中菜单项的 index path
      this.$emit('select', index, indexPath)
    },
    onOpen(index, indexPath) {
      // index: 打开的 sub-menu 的 index， indexPath: 打开的 sub-menu 的 index path
      this.$emit('open', index, indexPath)
    },
    onClose(index, indexPath) {
      // index: 收起的 sub-menu 的 index， indexPath: 收起的 sub-menu 的 index path
      this.$emit('close', index, indexPath)
    }
  }
}
</script>

<style lang="scss">
  // $primary: #3679FC;
  .ym-menu {
    border-right: 0 !important;
    background-color: transparent;
    user-select:none;
    a {
      text-decoration: none;
      outline: none;
    }
    div,ul,li{
      outline: none;
    }

    .el-menu-item {
      padding-left: 30px !important;
      height: $--aside-item-height !important; // 56px
      line-height: $--aside-item-height !important; // 56px
      font-size: $--font-size-medium;
      color: $--color-text-secondary;
    }
    .el-menu[role="menu"] {
      // padding-left: 20px;
    }
    .el-menu-item [class^="el-icon-"], .el-menu-item [class^="ym-icon-"]{
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: $--font-size-medium; //16px
      height: $--font-size-medium;
      line-height: 1;
    }
    .el-submenu [class^="el-icon-"], .el-submenu [class^="ym-icon-"] {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: $--font-size-medium; //16px
      color: #7C848E;
      height: $--font-size-medium;
      line-height: 1;
    }
    .el-submenu .el-menu-item {
      padding: 0 20px;
      padding-left: 50px !important;
    }
    .el-submenu__title {
      font-size: $--font-size-medium; //16px
      padding-left: 30px !important;
      color: $--color-text-secondary;
    }

    .ym-menu-icon {
      display: inline-block;
      font-size: 24px;
      vertical-align: middle;
      margin-right: 14px;
    }
    .el-submenu__icon-arrow {
      font-size: $--font-size-medium;
      transform: rotate(-90deg);
      color: #7C848E;
    }
    .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(0);
    }
    .el-menu-item.is-active {
      font-size: $--font-size-medium;
      font-weight: $--aside-font-active-weight;
      color: $--color-primary;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $--color-primary;
      }
      & [class^="el-icon-"] {
        font-size: $--font-size-medium; //16px
        color: $--color-primary;
      }
      & [class^="ym-icon-"] {
        font-size: $--font-size-medium; //16px
        color: $--color-primary;
      }
    }
    .el-submenu.is-active {
      color: $--color-primary;
      font-weight: $--aside-font-active-weight;
      & [class^="el-icon-"] {
        font-size: $--font-size-medium; //16px
      }
      & [class^="ym-icon-"] {
        font-size: $--font-size-medium; //16px
      }
      .el-submenu__title {
        color: $--color-primary;
        i {
          color: $--color-primary;
        }
      }
    }

    .logo-box {
      cursor: pointer;
      .sidebar-logo-container {
        height: 72px;
        line-height: 72px;
        overflow: initial;
      }
    }

    &.el-menu--collapse{
      .el-menu-item {
        padding-left: 20px !important;
      }
      .el-submenu__title {
        padding-left: 20px !important;
      }
    }
    // 水平导航栏样式
    &.el-menu--horizontal{
      border-bottom: none !important;

      & > div > a > .el-menu-item,
      & > div > .el-submenu {
        float: left;
        padding: 0 20px 0 0 !important;
        position: relative;
        height: $--aside-item-height !important; // 56px
        line-height: $--aside-item-height !important; // 56px
        &.is-opened,&:hover,&:focus {
          background-color: $--color-primary-light-9;
        }
        &.is-active{
          background-color: $--color-primary-light-9;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: auto;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $--color-primary;
          }
        }
        & > .el-submenu__title{
          .el-submenu__icon-arrow{
            transform: rotate(0deg);
          }
        }
        &.is-opened > .el-submenu__title{
          .el-submenu__icon-arrow{
            transform: rotate(180deg);
          }
        }

        .el-submenu__title{
          padding-left: 0 !important;
          padding-right: 20px;
          height: $--aside-item-height !important; // 56px
          line-height: $--aside-item-height !important; // 56px
        }
        .el-submenu__icon-arrow{
          right: 0;
        }

      }

    }

  }
  // 水平菜单栏弹出子菜单样式
  .el-menu--horizontal{
    .el-menu--popup {
      min-width: 160px;
      .el-menu-item.is-active {
        font-size: $--font-size-medium;
        font-weight: $--aside-font-active-weight;
        color: $--color-primary !important;
      }
    }
  }

</style>
