import axios from '../../plugins/axios'

// 获取机构信息
const getDepartmentList = (options) => axios.get('/v1/department/tree/' + options.deptId)
// 新增机构
const addDepartment = (options) => axios.post('/v1/department', options)
// 编辑机构
const editDepartment = (options) => axios.put('/v1/department', options)
// 查询机构下的用户
const getDepartmentUserList = (options) => axios.post('/v1/search/managers', options)
// 删除部门
const deleteDepartment = (options) => axios.delete('/v1/department/' + options.deptId)
// 根据部门类型获取部门列表
const getAllDepartmentList = (options) => axios.post('/v1/department/by/type', options)
// 获取机构类型
const getDepartmentTypes = () => axios.get('/v1/department/types')
// 获取机构详情
const getDepartmentDetail = (options) => axios.get('/v1/department/' + options.deptId)
// 获取鉴定资格
const getIdentifyTypes = () => axios.get('/v1/collection/identify/types')
// 获取鉴定项目
const getIdentifyItems = () => axios.get('/v1/detect/get/items')

export default {
  getDepartmentList,
  addDepartment,
  editDepartment,
  getDepartmentUserList,
  deleteDepartment,
  getAllDepartmentList,
  getDepartmentTypes,
  getDepartmentDetail,
  getIdentifyTypes,
  getIdentifyItems
}
