/**
 * 引入深拷贝函数
 */
import { deepClone } from './deepClone'

export function toTree(data, parentId = 'parentId', menuId = 'menuId') {
  /**
   * data ： 为所需转树的数组
   * parentId ： 为数组的父级Id,可自定义命名；若不传入，默认为'parentId'
   * menuId : 为数组自身Id，可自定义命名；若不传入，默认为'menuId'
   */
  // 对传入数据进行深拷贝防止数据改变
  var arr = deepClone([...data])
  var tree = []

  // 首先循环确定父对象
  for (let i = 0, len = arr.length; i < len; i++) {
    if (arr[i].parentId === -1 || !arr[i].parentId) {
      tree.push(arr[i])
      arr.splice(i, 1)
      // 删除数组的其中一个元素后，下次索引不需 +1 即可访问下一个元素，所以这里 -1 以抵消 +1
      i--
      len--
    }
  }
  getChild(arr, tree, parentId, menuId)
  return tree
}

function getChild(originArr, mapArr, parentId, menuId) {
  // 如果一维数组为空，则结束递归
  if (originArr.length === 0 || !mapArr) return

  // 循环遍历添加children属性
  for (let j = 0; j < mapArr.length; j++) {
    let item = mapArr[j]
    item.children = []
    // 循环遍历往children属性添加子对象
    for (let i = 0, len = originArr.length; i < len; i++) {
      let obj = originArr[i]

      if (item[menuId] === obj[parentId]) {
        item.children.push(obj)
        originArr.splice(i, 1)
        // 删除数组的其中一个元素后，下次索引不需 +1 即可访问下一个元素，所以这里 -1 以抵消 +1
        i--
        len--
      }
    }
    // 如果没有子节点，就删除children属性
    if (item.children.length === 0) {
      delete item.children
    } else {
      // 如果有子节点，就递归获取下一级子节点
      getChild(originArr, item.children, parentId, menuId)
    }
  }
}
