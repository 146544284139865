import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/permission'
import GlobalConfig from './config'
// import './assets/light-theme/index.css' // the element-ui-theme css
import 'element-ui/lib/theme-chalk/base.css'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/display.css'
import './assets/css/main.scss'
import myComponents from './components'
import './assets/icon/iconfont.css'
// import velocity from './plugins/velocity'
// import filters from './plugins/filters'
import api from './service'
import * as filters from './filters'

import '@/directive'

Vue.use(myComponents)
Vue.use(GlobalConfig)

Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

/**
 * 在生产环境使用mock数据
 * 如果不想在生产环境使用mock数据，将此段代码去掉即可
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// Vue.use(velocity)
// Vue.use(filters)
Vue.config.productionTip = false

Vue.prototype.$api = api

// 兼容ie的vue-router跳转
if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener('hashchange', function (event) {
    var currentPath = window.location.hash.slice(1)
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
