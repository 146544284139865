import axios from '../../plugins/axios'

// 创建藏品（档案）
const createRecord = (options) => axios.post('/v1/collection', options)

// 编辑藏品信息
const editRecord = (options) => axios.put('/v1/collection', options)

// 打码并生成暗码
const createCode = (options) => axios.put('/v1/collection/commit', options)

// 根据ID获取藏品详细信息
const getRecord = (options) => axios.get('/v1/collection/' + options.archiveId)

// 分页获取藏品
const getList = (options) => axios.post('/v1/collection/search', options)

// 分页获取藏品(鉴定机构用)
const getListExpert = (options) => axios.post('/v1/collection/search/expert', options)

// 鉴定管理获取运营人员能够查看的藏品列表
const getListOperator = (options) => axios.post('/v1/collection/search/operator', options)

// 根据档案ID查询操作记录
const getRecordOpts = (options) => axios.post('/v1/record/archive/' + options.archiveId)

// 提交初步审核-提交档案图文信息
const commitAudit = (options) => axios.post('v1/collection/commit/verify', options)

// 藏品初审
const firstAudit = (options) => axios.post('/v1/collection/verify', options)

// 眼鉴员鉴定
const eyeAudit = (options) => axios.put('/v1/collection/appraisal', options)

// 获取持有人信息
const getOwnerInfo = (options) => axios.get('/v1/owner/' + options.archiveId)

// 根据档案ID查询对应区块链信息
const getChain = (options) => axios.get('/v1/collection/chain/' + options.archiveId)

// 保存藏品图片
const savePicture = (options) => axios.post('/v1/collection/save/picture', options)

// 删除上传的文件
const removeFile = (options) => axios.delete('/file/v1/delete', options)

// 获取鉴定类型和指派的鉴定机构信息
const getAuditAssignInfo = (options) => axios.post('/v1/collection/identify', options)

// 保存初鉴填写内容
const saveFirstIdentify = (options) => axios.post('/v1/certificate', options)

// 列表页获取筛选项
const getDepartmentListByType = (options) => axios.post('/v1/department/current/by/type', options)

export default {
  createRecord,
  editRecord,
  createCode,
  getRecord,
  getList,
  getListExpert,
  getListOperator,
  getRecordOpts,
  commitAudit,
  firstAudit,
  eyeAudit,
  getOwnerInfo,
  getChain,
  savePicture,
  removeFile,
  getAuditAssignInfo,
  saveFirstIdentify,
  getDepartmentListByType
}
