<template>
  <div class='logo-container'>
    <transition name="LogoFade">
      <router-link class="logo-link" to="/">
        <template v-if="logoOptions.logoType=='all'">
          <img v-if="logoOptions.url" :src="logoOptions.url" class="logo">
          <h1 class="title">{{ logoOptions.title }} </h1>
        </template>
        <template v-if="logoOptions.logoType=='img'">
          <img v-if="logoOptions.url" :src="logoOptions.url" class="logo">
        </template>
        <template v-if="logoOptions.logoType=='text'">
          <h1 class="title">{{ logoOptions.title }} </h1>
        </template>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    logoOptions: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.LogoFade-enter-active {
  transition: opacity 1.5s;
}

.LogoFade-enter,
.LogoFade-leave-to {
  opacity: 0;
}

.logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;

  & .logo-link {
    height: 100%;
    width: 100%;

    & .logo {
      // width: 32px;
      height: 40px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .title {
      display: inline-block;
      margin: 0;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
}
</style>
