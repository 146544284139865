import axios from '../../plugins/axios'

// 查询初鉴专家列表
const getExpertList = () => axios.get('/v1/managers/identify')

// 获取订单列表
const getOrderList = (options) => axios.post('/v1/order/search', options)

// 导出订单
const getExport = (options) => axios.post('/v1/order/search/export', options, { responseType: 'blob' })

// 获取终鉴类型
const getFinalIdentifyType = () => axios.get('/v1/collection/final/identify/types')

// 获取鉴定机构列表
const getDepartmentListByTypes = (options) => axios.post('/v1/department/by/type', options)

export default {
  getExpertList,
  getOrderList,
  getExport,
  getFinalIdentifyType,
  getDepartmentListByTypes
}
