import axios from '../../plugins/axios'

// 获取当前藏品可指派的专家列表
const getExpertList = (options) => axios.get('/v1/managers/department/' + options.archiveId)

// 提交指派鉴定专家
const setExpert = (options) => axios.put('/v1/collection/identify/appoint', options)

export default {
  getExpertList,
  setExpert
}
