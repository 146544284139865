const baseUrl = window.BASE_URL
const fileUrl = window.FILE_URL
// const baseUrl = ''

const baseMockUrl = ''

// const uploadFileUrl = baseUrl + '/v1/collection/picture/archive' // 文件上传地址
const uploadFileUrl = fileUrl + '/v1/upload' // 文件上传地址

// const uploadCertificateUrl = baseUrl + '/v1/collection/picture/certificate' // 证书上传地址

// const downloadPdfUrl = baseUrl + '/file/upload/pdf' // PDF下载地址

export default {
  install (Vue, options) {
    Vue.prototype.baseMockUrl = baseMockUrl
    Vue.prototype.BaseUrl = baseUrl
    Vue.prototype.uploadFileUrl = uploadFileUrl
    Vue.prototype.FileUrl = fileUrl
    // Vue.prototype.uploadCertificateUrl = uploadCertificateUrl
    // Vue.prototype.downloadPdfUrl = downloadPdfUrl
  },
  baseUrl: baseUrl,
  baseMockUrl: baseMockUrl,
  uploadFileUrl: uploadFileUrl,
  fileUrl: fileUrl
  // uploadCertificateUrl: uploadCertificateUrl,
  // downloadPdfUrl: downloadPdfUrl
}
