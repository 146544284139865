<template>
  <div id="app" class="app">
    <transition-router>
      <router-view />
    </transition-router>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  name: 'app',
  data () {
    return {
      // 供会话保活使用
      //     countdown: 110 * 60 * 1000, // 毫秒，
      //     timer: null
      //   }
      // },
      // created() { },
      // mounted() {
      //   // this.keepAlive()
      // },
      // beforeDestroy() {
      //   clearInterval(this.timer)
      // },
      // computed: {
      //   ...mapGetters(['userInfo', 'countdownMark'])
      // },
      // watch: {
      //   userInfo(val) {
      //     if (val) {
      //       this.keepAlive()
      //     }
      //   },
      //   countdownMark() {
      //     this.keepAlive()
      //   }
      // },
      // methods: {
      //   // 会话保活
      //   keepAlive() {
      //     console.log('keepAlive')
      //     if (this.timer) {
      //       console.log('clear keepAlive')
      //       clearInterval(this.timer)
      //     }
      //     this.timer = setInterval(() => {
      //       let res
      //       try {
      //         res = this.$api.user.keepAlive()
      //       } catch (err) {
      //         console.log(err)
      //       }
      //       if (res && res.data && res.data.code === 0) {
      //         this.countdown = 110 * 60 * 1000
      //       } else {

      //       }
      //     }, this.countdown)
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  /*color: rgba(255, 255, 255, 0.9);*/
  position: relative;
}
</style>
