import axios from '../../plugins/axios'

// 获取当前系统全部路由用以新建角色权限
// 理论来说应该仅能获取当前账户可见菜单进行有限的权限分配, 但是目前菜单功能尚未完成并且会频繁修改, 因此暂时获取全部系统菜单
const getRouter = (options) => axios.get('/v1/privileges/menus', { params: options })

// 获取角色列表
const getUserRoleList = (options) => axios.get('/v1/roles', { params: options })

// 获取角色列表分页
const getUserRoleListByPage = (options) => axios.post('/v1/search/roles', options)

// 获取角色详情
const getUserRole = (options) => axios.get('/v1/roles/' + options)

// 新增角色
const addUserRole = (options) => axios.post('/v1/roles', options)

// 删除角色
const deleteUserRole = (options) => axios.delete('/v1/roles/' + options)

// 修改角色
const updateUserRole = (options) => axios.put('/v1/roles/' + options.roleId, options)

export default {
  getRouter,
  getUserRoleList,
  getUserRoleListByPage,
  getUserRole,
  addUserRole,
  deleteUserRole,
  updateUserRole
}
