import axios from '../../plugins/axios'

// 获取用户组列表
const getUserGroup = (options) => axios.get('/v1/groups', { params: options })

// 获取分页用户组列表
const getUserGroupByPage = (options) => axios.post('/v1/search/groups', options)

// 获取用户组所包含的用户
const getUserByGroup = (options) => axios.get('/v1/managers/' + options + '/group', { params: {} })

// 新增用户组
const addUserGroup = (options) => axios.post('/v1/groups', options)

// 修改用户组
const updateUserGroup = (options) => axios.put('/v1/groups/' + options.groupId, options)

// 删除用户组
const deleteUserGroup = (options) => axios.delete('/v1/groups/' + options)

// 获取用户组关联角色清单
const getUserRoleByUserGroup = (options) => axios.get('/v1/groups/' + options + '/roles', { params: {} })

// 修改用户组关联角色
const updateUserRoleOfUserGroup = (options) => axios.put('/v1/groups/' + options.groupId + '/roles', options)

export default {
  getUserGroup,
  getUserGroupByPage,
  getUserByGroup,
  addUserGroup,
  updateUserGroup,
  deleteUserGroup,
  getUserRoleByUserGroup,
  updateUserRoleOfUserGroup
}
